<template>
  <div>
    <my-header></my-header>
    <el-card class="box-card-box">
      <div class="header-box">
        <span>跟进详情</span>
        <el-button
          type="primary"
          :style="{ marginLeft: '1030px' }"
          @click="dialogFormVisible = true"
          >添加跟进</el-button
        >
      </div>
      <el-card class="box-card-1" shadow="never">
        <div class="box-card-1-1">
          <span>业务详情</span>
        </div>
        <div class="box-card-1-text">
          <div>
            <span>标题:</span>
          </div>
          <span>{{ itemList.title }}</span>
        </div>
        <div class="box-card-1-text">
          <div>
            <span>发布者姓名:</span>
          </div>
          <span>{{ itemList.name | name }}</span>
        </div>
        <div class="box-card-1-text">
          <div :style="{ zIndex: '99999' }">
            <span>地区:</span>
          </div>
          <span
            v-text="
              `${itemList.region[0]}${itemList.region[1]}${itemList.region[2]}`
            "
          ></span>
        </div>
        <div class="box-card-1-text">
          <div>
            <span>采购类型:</span>
          </div>
          <span>{{ itemList.purchaseType | purchaseType }}</span>
        </div>
        <div class="box-card-1-text">
          <div>
            <span>电子邮箱:</span>
          </div>
          <span>{{ itemList.title }}</span>
        </div>
        <div class="box-card-1-text">
          <div>
            <el-link @click.prevent="gowallBulletinDetail" type="primary"
              >查看更多</el-link
            >
          </div>
        </div>
      </el-card>
      <!-- 第一块结束 -->
      <el-card class="box-card-1" shadow="never">
        <div class="box-card-1-1">
          <span :style="{ width: '70px', minWidth: '70px' }">商机阶段</span>
          <span :style="{ fontSize: '14px', color: '#666' }"
            >(请点击选择该商机所处阶段)</span
          >
        </div>
        <div class="steps">
          <div
            @click="submitstage(1)"
            :class="active == 1 ? 'steps-active' : ''"
          >
            <span> 1.取得联系</span>
            <span :class="active == 1 ? 'steps-active-1' : ''"></span>
          </div>
          <div
            @click="submitstage(2)"
            :class="active == 2 ? 'steps-active' : ''"
          >
            <span> 2.确认需求方关键人员</span
            ><span :class="active == 2 ? 'steps-active-1' : ''"></span>
          </div>
          <div
            @click="submitstage(3)"
            :class="active == 3 ? 'steps-active' : ''"
          >
            <span>3.初步洽谈</span
            ><span :class="active == 3 ? 'steps-active-1' : ''"></span>
          </div>
          <div
            @click="submitstage(4)"
            :class="active == 4 ? 'steps-active' : ''"
          >
            <span>4.确认需求</span
            ><span :class="active == 4 ? 'steps-active-1' : ''"></span>
          </div>
          <div
            @click="submitstage(5)"
            :class="active == 5 ? 'steps-active' : ''"
          >
            <span>5.专项设计阶段</span
            ><span :class="active == 5 ? 'steps-active-1' : ''"></span>
          </div>
          <div
            @click="submitstage(6)"
            :class="active == 6 ? 'steps-active' : ''"
          >
            <span>6.投标阶段或直接议价</span
            ><span :class="active == 6 ? 'steps-active-1' : ''"></span>
          </div>
          <div
            @click="submitstage(7)"
            :class="active == 7 ? 'steps-active' : ''"
          >
            <span>7.制作标书</span
            ><span :class="active == 7 ? 'steps-active-1' : ''"></span>
          </div>
          <div
            @click="submitstage(8)"
            :class="active == 8 ? 'steps-active' : ''"
          >
            <span>8.中标结果</span>
          </div>
        </div>
      </el-card>
      <!-- 第二块结束 -->
      <el-card class="box-card-1" shadow="never">
        <!-- 跟进记录 -->
        <el-table :data="recordList" border style="width: 100%">
          <el-table-column prop="createTime" label="跟进时间" width="180">
          </el-table-column>
          <el-table-column prop="content" label="跟进内容" width="600">
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <span :style="{ color: scope.row.state == 1 ? 'blue' : 'red' }">{{
                scope.row.state | state
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="跟进方式"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handlelook(scope.$index, scope.row)"
                >查看</el-button
              >
              <el-button
                size="mini"
                type="primary"
                @click="handleupdate(scope.$index, scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          class="el-pagination"
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="total"
          @current-change="currentchange1"
          :hide-on-single-page="true"
        >
        </el-pagination>
        <!-- 分页 -->
      </el-card>
    </el-card>
    <!-- 查看业务详情 -->
    <!-- <el-dialog title="业务详情" :visible.sync="lookitemshow">
      <div class="detail">
        <div>
          <span>标题:</span><span>{{ itemList.title }}</span>
        </div>
        <div>
          <span>发布者姓名:</span><span>{{ itemList.name }}</span>
        </div>
        <div>
          <span>发布者电话:</span><span>{{ itemList.phone }}</span>
        </div>
        <div>
          <span>地区:</span><span v-text="`${itemList.region[0]}${itemList.region[1]}${itemList.region[2]}`"></span>
        </div>
        <div>
          <span>项目联系人:</span><span>{{ itemList.projectContact }}</span>
        </div>
        <div>
          <span>项目联系人电话:</span><span>{{ itemList.项目联系人电话 }}</span>
        </div>
        <div>
          <span>采购类型:</span><span>{{ itemList.purchaseType | purchaseType }}</span>
        </div>
        <div>
          <span>预计最佳开始追踪时间:</span><span>{{ itemList.startTrackingTime }}</span>
        </div>
        <div>
          <span>预计需求时间:</span><span>{{ itemList.demandTime }}</span>
        </div>
        <div>
          <span>业务类型:</span><span>{{ itemList.type==1?'单人':'多人' }}</span>
        </div>
        <div>
          <span>预算:</span><span>{{ itemList.budget }}</span>
        </div>
        <div>
          <span>业务所在地址:</span><span>{{ itemList.address }}</span>
        </div>
        <div>
          <span>状态:</span
          ><span
            v-text="
              lookitemList.state == 1
                ? '成功'
                : lookitemList.state == 2
                ? '失败'
                : '未知'
            "
          ></span>
        </div>
      </div>
    </el-dialog> -->
    <!-- 添加跟进详情 -->
    <el-dialog title="跟进信息" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item required label="招标单位" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入招标单位"
            v-model="form.bidsUnit"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item required label="联系人" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入联系人"
            v-model="form.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item required label="联系人电话" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入联系人电话"
            v-model="form.phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item required label="状态" :label-width="formLabelWidth">
          <el-select placeholder="请选择状态" v-model="form.state">
            <el-option label="成功" value="1"></el-option>
            <el-option label="失败" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="电子邮箱" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入电子邮箱"
            v-model="form.email"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          required
          label="跟进内容记录"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="form.content"
            maxlength="120"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item required label="跟进方式" :label-width="formLabelWidth">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">电话</el-radio>
            <el-radio :label="2">发短信</el-radio>
            <el-radio :label="3">发邮件</el-radio>
            <el-radio :label="4">当面拜访</el-radio>
            <el-radio :label="5">其他</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addrecord">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 查看跟进详情 -->
    <el-dialog title="跟进详情" :visible.sync="lookitemListactive">
      <div class="detail">
        <div>
          <span>招标单位:</span><span>{{ lookitemList.bidsUnit }}</span>
        </div>
        <div>
          <span>联系人:</span><span>{{ lookitemList.name }}</span>
        </div>
        <div>
          <span>联系人电话:</span><span>{{ lookitemList.phone }}</span>
        </div>
        <div>
          <span>更新时间:</span><span>{{ lookitemList.updateTime }}</span>
        </div>
        <div>
          <span>跟进内容:</span><span>{{ lookitemList.content }}</span>
        </div>
        <div>
          <span>跟进方式:</span><span>{{ lookitemList.type }}</span>
        </div>
        <div>
          <span>电子邮箱:</span><span>{{ lookitemList.email }}</span>
        </div>
        <div>
          <span>状态:</span
          ><span
            v-text="
              lookitemList.state == 1
                ? '成功'
                : lookitemList.state == 2
                ? '失败'
                : '未知'
            "
          ></span>
        </div>
      </div>
    </el-dialog>
    <!-- 修改跟进详情 -->
    <el-dialog title="修改跟进详情" :visible.sync="updateitemListactive">
      <el-form :model="updateitemList">
        <el-form-item label="招标单位" :label-width="formLabelWidth">
          <el-input
            v-model="updateitemList.bidsUnit"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" :label-width="formLabelWidth">
          <el-input v-model="updateitemList.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话" :label-width="formLabelWidth">
          <el-input
            v-model="updateitemList.phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" :label-width="formLabelWidth">
          <el-input
            v-model="updateitemList.email"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-radio-group v-model="updateitemList.state">
            <el-radio label="1">成功</el-radio>
            <el-radio label="2">失败</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跟进内容" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="updateitemList.content"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="跟进方式" :label-width="formLabelWidth">
          <el-radio-group v-model="updateitemList.type">
            <el-radio label="1">电话</el-radio>
            <el-radio label="2">发短信</el-radio>
            <el-radio label="3">发邮件</el-radio>
            <el-radio label="4">当面拜访</el-radio>
            <el-radio label="5">其他</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateitemListactive = false">取 消</el-button>
        <el-button type="primary" @click="handleupdateitemList"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      id: "",
      active: 1,
      //  当前业务信息
      itemList: [],
      // 查看业务详情
      lookitemshow: true,
      //   查看跟进记录详情
      lookitemList: [],
      lookitemListactive: false,
      //修改记录详情
      updateitemList: [],
      updateitemListactive: false,

      formLabelWidth: "120px",
      //   跟进记录
      recordList: [],
      // 编辑表单显示隐藏
      dialogFormVisible: false,
      //   跟进内容
      form: {
        businessId: "", //String，业务id
        bidsUnit: "", //String，招标单位
        name: "", //String，联系人
        phone: "", //String，联系人电话
        state: "", //String，1.成功 2.失败
        content: "", //String，跟进内容
        type: 1, //String，跟进方式:1.电话2.发短信3.发邮件4.当面拜访5其他
        email: "", //String，电子邮箱
      },
      pageNum: 1,
      pageSize: 5,
      total: 0,
    };
  },
  filters: {
    purchaseType(value) {
      if (value == 1) {
        return "厨房设备采购";
      } else if (value == 2) {
        return "厨房服务采购";
      } else if (value == 3) {
        return "食材采购";
      } else if (value == 4) {
        return "私有业务";
      } else {
        return "未选择";
      }
    },
    state(value) {
      if (value == 1) {
        return "成功";
      } else if (value == 2) {
        return "失败";
      }
    },
    name(value) {
      if (!value) {
        return "未填写";
      } else if (value) {
        return value;
      }
    },
  },
  methods: {
    // 去往详情页
    gowallBulletinDetail() {
      console.log(1);
      this.$router.push({
        path: "/wallBulletinDetail",
        query: {
          item: JSON.stringify(this.itemList),
        },
      });
    },
    //获取当前业务
    async getItem() {
      this.itemList = JSON.parse(this.$route.query.item);
      this.active = this.itemList.businessUser.state;
      this.recordList = this.itemList.businessFollowList;
      console.log(this.itemList);
    },
    // 页面改变时的回调函数
    currentchange1(p) {
      console.log(p);
      this.pageNum = p;
      this.getrecord();
    },
    // 获取跟进记录
    async getrecord() {
      console.log(this.itemList.id);
      console.log(this.itemList.businessUserId);
      console.log(this.itemList.userId);
      const res = await this.$http({
        method: "get",
        url: `front/businessFollow/findByBusinessId?businessId=${this.itemList.id}&orderByType=create_time desc&pageNum=${this.pageNum}&pageSize=${this.pageSize}`,
      });
      this.pageNum = 1;
      console.log(res);
      this.total = res.data.data.total;
      console.log(res.data.data);
      this.recordList = [];
      res.data.data.list.map((v, k) => {
        v.type == 1
          ? (v.type = "电话")
          : v.type == 2
          ? (v.type = "发短信")
          : v.type == 3
          ? (v.type = "发邮件")
          : v.type == 4
          ? (v.type = "当面拜访")
          : v.type == 5
          ? (v.type = "其他")
          : "其他";

        this.recordList.push(v);
      });
      console.log(this.recordList);
    },
    // 查看跟进记录详情
    handlelook(k, v) {
      console.log(k, v);
      this.lookitemListactive = true;
      this.lookitemList = v;
    },
    // 修改跟进记录
    handleupdate(k, v) {
      let a = { ...v };
      a.type == "电话"
        ? (a.type = "1")
        : a.type == "发短信"
        ? (a.type = "2")
        : a.type == "发邮件"
        ? (a.type = "3")
        : a.type == "当面拜访"
        ? (a.type = "4")
        : a.type == "其他"
        ? (a.type = "5")
        : "其他";
      this.updateitemList = a;
      this.updateitemListactive = true;
      console.log(this.updateitemList);
    },
    async handleupdateitemList() {
      const res = await this.$http({
        method: "post",
        url: "front/businessFollow/update",
        data: {
          id: this.updateitemList.id,
          businessId: this.updateitemList.businessId,
          bidsUnit: this.updateitemList.bidsUnit,
          name: this.updateitemList.name,
          phone: this.updateitemList.phone,
          state: this.updateitemList.state,
          content: this.updateitemList.content,
          type: this.updateitemList.type,
          email: this.updateitemList.email,
        },
      });
      if (res.data.status == 200) {
        this.updateitemListactive = false;
        this.reload();
        this.$message({
          message: "修改成功",
          type: "success",
        });
      }
    },
    // 提交商机阶段
    async submitstage(active) {
      this.active = active;
      const res = await this.$http({
        method: "post",
        url: "front/businessUser/update",
        data: {
          id: this.itemList.businessUserId,
          businessId: this.itemList.id,
          businessUserId: this.itemList.userId,
          state: this.active,
        },
      });
      console.log(res);
    },
    // 添加跟进记录
    async addrecord() {
      this.dialogFormVisible = false;
      let form = this.form;
      //   业务跟进内容
      const res = await this.$http({
        method: "post",
        url: `front/businessFollow/save`,
        data: {
          businessId: this.itemList.id, //String，业务id
          bidsUnit: form.bidsUnit, //String，招标单位
          name: form.name, //String，联系人
          phone: form.phone, //String，联系人电话
          state: form.state, //String，1.成功 2.失败
          content: form.content, //String，跟进内容
          type: form.type, //String，跟进方式:1.电话2.发短信3.发邮件4.当面拜访5其他
          email: form.email, //邮箱
        },
      });
      if (res.data.status == 200) {
        this.$message({
          message: "添加成功",
          type: "success",
        });
        this.reload();
      }
    },
    async handleDelete(k, v) {
      const res = await this.$http({
        method: "post",
        url: "front/businessFollow/delete",
        data: {
          id: v.businessId,
        },
      });
      if (res.data.status == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
          showClose: true,
        });
        this.reload();
      } else {
        this.$message.error("删除失败");
      }
    },
  },
  created() {
    (async () => {
      await this.getItem();
      await this.getrecord();
    })();
  },
};
</script>

<style lang="scss" scoped>
.detail {
  div {
    padding: 5px 0;
    & > span:nth-child(1) {
      display: inline-block;
      width: 40%;
      text-align: right;
      color: #666;
    }
    & > span:nth-child(2) {
      margin-left: 15px;
      color: #777;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
}
.box-card-box {
  width: 88%;
  min-width: 1350px;
  margin: 0 auto;
  margin-top: 160px;
  background: #f6f6f6;
}
.box-card-1 {
  margin: 8px 0;
}
.box-card-1-1 {
  display: flex;
  align-items: center;
}
.box-card-1-1 > span:nth-child(1) {
  width: 98%;
  min-width: 600px;
  margin-left: 8px;
}
.box-card-1-1::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 18px;
  /* margin: 8px 5px 0px 0px; */
  background: #429fff;
}
.header-box {
  font-size: 18px;
  font-weight: 600;
  margin: 5px;
  padding-left: 10px;
}
.box-card-1-text > div:nth-child(1) {
  display: inline-block;
  width: 80px;
  text-align: right;
  font-size: 14px;
}
.box-card-1-text > span:nth-child(2) {
  padding-left: 8px;
  font-size: 14px;
  color: #666;
}
.steps {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.steps div {
  flex: 1;
  text-align: center;
  color: #666;
  background: #f6f6f6;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.steps div > span:nth-child(1) {
  font-size: 12px;
  line-height: 35px;
  z-index: 9999;
  padding-left: 10px;
}
.steps > div:nth-child(1) {
  z-index: 100;
}
.steps > div:nth-child(2) {
  z-index: 90;
}
.steps > div:nth-child(3) {
  z-index: 80;
}
.steps > div:nth-child(4) {
  z-index: 70;
}
.steps > div:nth-child(5) {
  z-index: 60;
}
.steps > div:nth-child(6) {
  z-index: 50;
}
.steps > div:nth-child(7) {
  z-index: 40;
}
.steps > div:nth-child(8) {
  z-index: 30;
}
.steps > div > span:nth-child(2) {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0px;
  top: 0;
  background: #f6f6f6;
  transform: rotate(45deg) translate(12.5px, -4.5px);
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}
.steps-active {
  color: #fff !important;
  background: #429fff !important;
}
.steps-active-1 {
  color: #fff !important;
  background: #429fff !important;
  border-right: 2px solid #429fff !important;
  border-top: 2px solid #429fff !important;
}
.el-radio {
  margin-right: 15px;
}
/* 分页 */
.el-pagination {
  margin-left: 80%;
  margin-top: 15px;
}
</style>